import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ProjectOnePage = () => (
  <Layout>
    <SEO title="Video" />
    <div className="container" style={{marginTop: '80px', backgroundColor: 'black', borderRadius: '10px', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}}>
      <div className="row">
        <div className="col-md-12" style={{padding: '25px 20px 0px 20px'}}>
            <iframe title="video-one" style={{width: '100%', height: '50vh'}} src="https://www.youtube.com/embed/zmLKtUhXYeY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </Layout>
)

export default ProjectOnePage
